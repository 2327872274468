<template>
    <!-- <v-md-editor v-model="text" class="editor"  :toolbar="toolbar"></v-md-editor> -->
    <v-md-editor v-model="editorContent" class="editor" :disabled-menus="[]" @upload-image="handleUploadImage"  @save="saveBlog"/>
    <div>
      <a-modal v-model:open="modelOpen" :afterClose="afterClose">

        <template #footer>
          <a-button key="back" @click="handleCancel">返回</a-button>
          <a-button type="primary" :loading="modelLoading" @click="handleOk">保存</a-button>
        </template>

        <a-card :bordered="true">
          <a-form ref="formRef" :model="formState" :label-col="{style:{width:'50px'}}" :wrapper-col="{span: 25}">

            <a-form-item name="name" :rules="[{ required: true, message: '标题不能为空!' }]">
              <a-input v-model:value="formState.name" placeholder="标题"/>
            </a-form-item>

            <a-form-item name="menuValue" :rules="[{ required: true, message: '选择菜单不能为空!' }]">
              <a-cascader v-model:value="formState.menuValue" :options="menuOptions" @change="menuChange" expand-trigger="hover" placeholder="菜单" />
            </a-form-item>

            <!-- multiple -->
            <a-form-item >
              <a-select
                v-model:value="formState.labelSelecteds"
                mode="tags"
                style="width: 100%"
                placeholder="标签"
                :options="labelOptions"
              ></a-select>
            </a-form-item>

            <a-form-item >
              <a-textarea v-model:value="formState.omitContent" placeholder="内容的简单描述（不填默认为内容前一百的字符！）" allow-clear />
            </a-form-item>

            <a-form-item name="secretKey" :rules="[{ required: true, message: '保存操作秘钥不能为空!' }]">
              <a-input v-model:value="formState.secretKey" placeholder="保存操作秘钥"/>
            </a-form-item>

            <a-form-item name="imageUrl" :rules="[{ required: true, message: '请上传图标!' }]">
              <a-upload
                v-model:file-list="formState.fileList"
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :before-upload="beforeUpload"
                :customRequest="uploadTitleImage"
              >
                <img v-if="formState.imageUrl" :src="formState.imageUrl" alt="avatar" style="width: 100px;height: 100px;"/>
                <div v-else>
                  <loading-outlined v-if="formState.addLoading"></loading-outlined>
                  <plus-outlined v-else></plus-outlined>
                  <div class="ant-upload-text">图标</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-form>
        </a-card>

      </a-modal>
    </div>
    
</template>

<script setup>
import { reactive, ref } from 'vue';
import { setRsa } from '@/utils/unit'
import {uploadFile, getMenus, delImage, getLabels, pushContent} from '@/api/blogApi';
import { message } from 'ant-design-vue';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';

const formRef = ref();
const modelOpen = ref(false);
const modelLoading = ref(false);
const editorContent = ref('');

const menuOptions = ref([])
const labelOptions = ref([]);
const formState = reactive({
    name: '',
    menuValue:'',
    omitContent:'',
    fileList:[],
    imageUrl:'',
    secretKey: '',
    labelSelecteds:[],
    addImageUrl: '',
    addImageName: '',
    addLoading:false
});

const saveBlog = () =>{
  modelOpen.value = true
}

const afterClose = () => {
  // 关闭弹框后触发
};

const handleCancel = () => {
  modelOpen.value = false
};

const handleOk = async () => {
  try {
    if(editorContent.value == ''){
      message.error("文章内容不能为空！");
      return
    }
    const formValues = await formRef.value.validateFields();
    let pushData = {};
    pushData.key = getRsaKey();
    pushData.title = formValues.name;
    pushData.menuKey = formValues.menuValue[formValues.menuValue.length - 1]
    pushData.label = formState.labelSelecteds.join(',');
    pushData.omitContent = formState.omitContent;
    pushData.content = editorContent.value;
    pushData.image = formState.addImageUrl

    pushContent(pushData).then((res) => {
      if(res.success == undefined || !res.success){
        message.error(res.errorMessage);
      }else{
        message.info('保存成功!')
      }
    }).catch((err) => {
      alert("保存文章异常!");
      console.log(err);
    });
    
  } catch (errorInfo) {
    message.error('保存文章异常!', errorInfo);
  }
};

const menuChange = () => {

  if(formState.menuValue == undefined || formState.menuValue.length == 0){
    labelOptions.value = []
    formState.labelSelecteds = [];
    return
  }

  let getLabelData = {};
  getLabelData.preKey = formState.menuValue[0];
  getLabels(getLabelData).then((res) => {

    if(res.success == undefined || !res.success){
      message.error(res.errorMessage);
    }else{

      labelOptions.value = []
      formState.labelSelecteds = [];
      for(let index in res.data){
        labelOptions.value.push({
          label: res.data[index].name, 
          value: res.data[index].dictKey})
      }
    }
    }).catch((err) => {
      console.log("初始化标签异常!");
      console.log(err);
    });
};

const handleUploadImage = (event, insertImage, files) =>{
  var encryptKey = getRsaKey();
  if(encryptKey == undefined){
    return
  }

  let uploadData = new FormData();
  uploadData.append('file', files[0]);
  uploadData.append('secretKey', encryptKey)
  uploadFile(uploadData).then((res) => {
    
    if(res.success == undefined || !res.success){
      message.error(res.errorMessage);
    }else{
      insertImage({
        url: res.data.url,
        desc: '',
        width: 'auto',
        height: 'auto',
      });
    }
    
  }).catch((err) => {
    message.error(err);
  });
}

const beforeUpload = (file) =>{
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
const getBase64 = (img, callback) =>{
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
const uploadTitleImage = (fileInfo) => {

  var encryptKey = getRsaKey();
  if(encryptKey == undefined){
    return
  }

  // 暂存上次图片url
  let beforeImageUrl = formState.imageUrl
  formState.imageUrl = ''

  // 开始上次开启loading
  formState.addLoading = true;

  // 上传到服务器
  let uploadData = new FormData();
  uploadData.append('file', fileInfo.file);
  uploadData.append('secretKey', encryptKey)
  uploadFile(uploadData).then((res) => {
    
    if(res.success == undefined || !res.success){
      message.error(res.errorMessage);
      // 失败用上次的图片
      formState.imageUrl = beforeImageUrl
      formState.addLoading = true;
    }else{

      // 图片完整地址
      formState.addImageUrl = res.data.url
      // 暂存成功上次的图片，如果不点确认则删掉
      formState.addImageName = res.data.fileName
      // 成功回显
      getBase64(fileInfo.file, base64Url => {
        formState.imageUrl = base64Url;
        formState.addLoading = false;
      });
    }
  }).catch((err) => {
    formState.addLoading = false;
    message.error(err);
  });
}


const initData = () => {
   getMenus().then((res) => {
    var array = []
    menuOptions.value = []
    if(res.data != null && res.data.length > 0){
      for(let index in res.data){
        let menuInfo = res.data[index]
        let firstMenu = {
          value: menuInfo.dictKey,
          label: menuInfo.name,
        }
        if(menuInfo.childrenList.length > 0){
          firstMenu.children = getChildren(menuInfo.childrenList)
        }
        array.push(firstMenu)
      }
    }
    menuOptions.value = array
  }).catch((err) => {
    console.log("初始化菜单异常!");
    console.log(err);
  });
}


const getChildren = (childrenList) =>{

  var childrens = []
  for(let index in childrenList){

    let menuInfo = childrenList[index]
    let menu = {
      value: menuInfo.dictKey,
      label: menuInfo.name,
    }
    if(menuInfo.childrenList.length > 0){
      menu.children = getChildren(menuInfo.childrenList)
    }
    childrens.push(menu)
  }
  return childrens;
}
initData()

const getRsaKey = () =>{
  var secretKey = formState.secretKey;
  if(secretKey == ''){
    message.error("秘钥为空！");
    return
  }

  return setRsa(secretKey);
}

// 删除上一张图片
// var addImageName = formState.addImageName;
// if(addImageName != ''){
//   let delImageData = {};
//   delImageData.fileName = addImageName;
//   delImageData.secretKey = encryptKey;
//   delImage(delImageData).then((res) => {
//     if(res.success == undefined || !res.success){
//       message.error(res.errorMessage);
//     }else{
//       console.log("删除成功！")
//       console.log(addImageName)
//     }
//   }).catch((err) => {
//     console.log(err)
//     console.log("删除" + addImageName + "异常!")
//   });
// }

</script>

<style>
.wrapper{
    flex: 1;
}
.ant-form-item{
  margin-bottom: 10px !important;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>