<template>
    <v-md-preview :text="editorContent"></v-md-preview>
</template>
<script setup>

import "../assets/css/theme-4.css"
import {useRoute} from 'vue-router'
import { ref } from 'vue';
import {getContent} from '@/api/blogApi';

const editorContent = ref('');

const initData = () => {
    let contentRequest = {};
    contentRequest.id = useRoute().params.id;
    getContent(contentRequest).then((res) => {
        
        if(res.success == undefined || !res.success){
            message.error(res.errorMessage);
        }else{
            editorContent.value = res.data.content
        }
    }).catch((err) => {
        console.log("加载文章内容异常!");
        console.log(err);
    });
}
initData()
</script>