<template>

    <div class="main-wrapper">
	    <section class="blog-list px-3 py-5 p-md-5">
		    <div class="container">
			    <div class="item mb-5" v-for="item in dataList">
				    <div class="media">
					    <img class="mr-3 img-fluid post-thumb d-none d-md-flex" :src="item.img" alt="image">
					    <div class="media-body">
						    <h3 class="title mb-1"><a href="#" @click="toDetail(item.id)">{{item.title}}</a></h3>
						    <div class="meta mb-1"><span class="date">{{item.pushTime}}</span><span class="time">{{item.readTime}}</span></div>
						    <div class="intro">{{item.omitContent}}</div>
						    <a class="more-link" href="#">更多 &rarr;</a>
					    </div>
				    </div>
			    </div>

			    <nav class="blog-nav nav nav-justified my-5">
				  <input :hidden="true" v-model="currentPage">
				  <a class="nav-link-prev nav-item nav-link rounded-left" href="#" @click="previousPage">上一页<i class="arrow-prev fas fa-long-arrow-alt-left"></i></a>
				  <a class="nav-link-next nav-item nav-link rounded-right" :style="{pointerEvents: isAuto}" href="#" @click="nextPage">下一页<i class="arrow-next fas fa-long-arrow-alt-right"></i></a>
				</nav>
		    </div>
	    </section>
    </div>
</template>
<script setup>
import "../assets/css/theme-4.css"
import { ref } from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {getContentList} from '@/api/blogApi';


const route = useRoute()
const router = useRouter()

const total = ref(0)
const isAuto = ref('auto')
const currentPage = ref(2)
const dataList = ref([])

const toHome = () =>{
	if(route.params == undefined || route.params.menuKey == undefined){
		router.push('/');
	}else{
		router.push('/' + route.params.menuKey);
	}
}

const toDetail = (id) => {
	router.push({path: '/detail/' + id});
}
const previousPage = () => {
	var page = currentPage.value - 1;
	if(page == 1){
		toHome();
	}
	currentPage.value = page
	initData();
}
const nextPage = () => {
	currentPage.value = currentPage.value + 1;
	initData();
}
const initData = (menuKey) => {
	
    let contentRequest = {};
	contentRequest.currentPage = currentPage.value
	if(menuKey != undefined){
		contentRequest.menuKey = menuKey;
	}else{
		if(menuKey == undefined && route.params == undefined){
			menuKey = 'undefined'
		}else{
			if(route.params.menuKey == undefined){
				menuKey = 'undefined'
			}else{
				menuKey = route.params.menuKey;
			}
		}
		contentRequest.menuKey = menuKey;
	}
    getContentList(contentRequest).then((res) => {
		
		dataList.value = []
        if(res.success == undefined || !res.success){
            message.error(res.errorMessage);
        }else{

			total.value = res.data.records.length;
			if(total.value < 10){
				isAuto.value = 'none'
			}else{
				isAuto.value = 'auto'
			}

			if(res.data.records.length > 0){
				for(let index in res.data.records){
					var record = res.data.records[index];
					dataList.value.push({
						id: record.id,
						title: record.title,
						img: record.image,
						pushTime: record.createTime.replace(".000+00:00",""),
						label: record.label,
						omitContent: omitContentLength(record.omitContent, record.content)
					})
				}
			}else{
				dataList.value.push({
					id: 12345,
					title: '默认标题（该类型没有相关文章）',
					img: require('../assets/images/blog/blog-post-thumb-1.jpg'),
					pushTime: 'Published 1 months ago',
					label: '4 min read',
					omitContent: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies...'
				})
			}
        }
    }).catch((err) => {
        console.log("加载文章列表异常!");
        console.log(err);
    });
}
const omitContentLength = (omitContent, content) =>{
	
	if(omitContent == undefined || omitContent == ''){
		omitContent = content.substr(0, 110);
	}
	
	var le = omitContent.length;
	if(le < 110){
		for(let i = 0; i < 110 - le; i ++){
			omitContent = omitContent + " . "
		}
	}
	return omitContent;
}
initData()
</script>