import request from '@/utils/request'

export function uploadFile(files) {
    return request.uploadFile('/api/uploadImage', files)
}

export function getMenus() {
    return request.get('/api/getMenus')
}

export function delImage(fileName) {
    return request.post('/api/delImage', fileName)
}

export function getLabels(data) {
    return request.get('/api/getLabels', data)
}

export function pushContent(data) {
    return request.post('/api/pushContent', data)
}

export function getContent(data) {
    return request.get('/api/getContent', data)
}

export function getContentList(data) {
    return request.get('/api/getContentList', data)
}

export function searchContentList(data) {
    return request.get('/api/searchContentList', data)
}