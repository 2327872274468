<template>
    <header class="header text-center">	    
	    <h1 class="blog-name pt-lg-4 mb-0"><a href="/">千搜万索何处有</a></h1>
        
	    <nav class="navbar navbar-expand-lg navbar-dark" >
           
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
			</button>

			<div id="navigation" class="collapse navbar-collapse flex-column" >
				<div class="profile-section pt-3 pt-lg-0">
				    <img class="profile-image mb-3 rounded-circle mx-auto" src="../assets/images/touxiang.jpg" alt="image" >			
					
					<div class="bio mb-3">群峭碧摩天，逍遥不记年。拨云寻古道，倚石听流泉。花暖青牛卧，松高白鹤眠。语来江色暮，独自下寒烟。<br><!--<a href="about.html">Find out more about me</a>--></div><!--//bio-->
					<ul class="social-list list-inline py-3 mx-auto">
			            <li class="list-inline-item"><a href="/"><i class="fas fa-home fa-fw"></i></a></li>
			            <li class="list-inline-item"><a href="#"><i class="fas fa-user fa-fw"></i></a></li>
			            <!-- <li class="list-inline-item"><a href="#" @click="toAddBlog"><i class="fas fa-edit fa-fw"></i></a></li> -->
			            <li class="list-inline-item"><a href="#"><i class="fas fa-star fa-fw"></i></a></li>
			            <li class="list-inline-item"><a href="#"><i class="fas fa-wallet fa-fw"></i></a></li>
			        </ul><!--//social-list-->
			        <hr> 
				</div><!--//profile-section-->
				
				<!-- <div class="my-2 my-md-3">
				    <a class="btn btn-primary" href="#" target="_blank">Get in Touch</a>
				</div> -->

				<a-menu
					v-model:openKeys="openKeys"
					v-model:selectedKeys="selectedKeys"
					style="width: 180px; font-weight:700; font-size:medium; color:honeydew; text-align:left"
					mode="inline"
					:theme="theme"
					:items="items"
					@click="handleClick"
				/>

			</div>
		</nav>
    </header>
</template>
<script setup>
import { h, ref } from 'vue';
import {getMenus} from '@/api/blogApi';
import {useRouter} from 'vue-router';
import {AppstoreOutlined} from '@ant-design/icons-vue';

const items = ref([])
const theme = ref('bule')
const openKeys = ref([])
const selectedKeys = ref([])

const router = useRouter()
const handleClick = (menuInfo) =>{
	var key = menuInfo.key
	router.push({path: '/' + key, query: { ishref: true }});
}

const initMenu = () => {
	getMenus().then((res) => {

		var array = []
		items.value = []
		if(res.data == null || res.data.length <= 0){
			return;
		}
		for(let index in res.data){

			let menuInfo = res.data[index]
			let firstMenu = {
				key: menuInfo.dictKey,
				icon: () => h(AppstoreOutlined),
				label: menuInfo.name,
				title: menuInfo.name
			}

			if(menuInfo.childrenList.length > 0){
				firstMenu.children = getChildren(menuInfo.childrenList)
			}
			
			array.push(firstMenu)
		}
		items.value = array
	}).catch((err) => {
		console.log(err);
	});
}
const getChildren = (childrenList) => {

	var childrens = []
	for(let index in childrenList){
		let menuInfo = childrenList[index]
		let menu = {
			key: menuInfo.dictKey,
			label: menuInfo.name,
			title: menuInfo.name
		}
		if(menuInfo.childrenList.length > 0){
			menu.children = getChildren(menuInfo.childrenList)
		}
		childrens.push(menu)
	}
	return childrens;
}
initMenu()
import "../assets/js/all.js"
import "../assets/plugins/jquery-3.3.1.min.js"
import "../assets/plugins/popper.min.js"
import "../assets/plugins/bootstrap/js/bootstrap.min.js"
import "../assets/css/theme-4.css"
</script>
<style>
.scroll-pane
{
    width: 100%;
    height: 200px;
    overflow: auto;
}
</style>