<template>
    <footer class="footer text-center py-2 theme-bg-dark">
        <small class="copyright">
          Copyright &copy; 2023 个人学习资料分享 hkolo.com All Rights Reserved. 备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备19011927号-1</a>
        </small>
     </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>