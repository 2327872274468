<template>
  <div class="wrapper">
    <my-header />
    <router-view></router-view>
  </div>
  <Footer />
</template>

<script>
import MyHeader from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    MyHeader,
    Footer
  }
}
</script>
<style>
#app {
  height: 100%;
}
.wrapper {
  display: flex;
  min-height: calc(100% - 40px);
}

.header {
  position: relative;
  height: auto;
}

/*.main-wrapper {
  margin-left: 300px;
}
*/
@media only screen and (max-width: 990px) {
  .wrapper {
    display: block;
    position: relative;
  }
  .v-md-editor {
    height: 87vh;
  }
}</style>