<template>

    <div class="main-wrapper">
	    <section class="cta-section theme-bg-light py-5">
		    <div class="container text-center">
			    <h2 class="heading">众里寻他千百度,蓦然回首,那人却在,灯火阑珊处！</h2>
			    <a-form class="signup-form form-inline justify-content-center pt-3">
                    <div class="form-group">
                        <label class="sr-only" for="semail"></label>
                        <input  v-model="searchTitle" id="semail" name="semail1" class="form-control mr-md-1 semail" placeholder="标题关键字">
                    </div>
                    <button type="primary" class="btn btn-primary" @click="search">蓦然回首</button>
                </a-form>
		    </div>
	    </section>
	    <section class="blog-list px-3 py-5 p-md-5">
		    <div class="container">

				<div class="item mb-5" v-for="item in dataList">
				    <div class="media">
					    <img class="mr-3 img-fluid post-thumb d-none d-md-flex" :src="item.img" alt="image">
					    <div class="media-body">
						    <h3 class="title mb-1"><a href="#" @click="toDetail(item.id)">{{item.title}}</a></h3>
						    <div class="meta mb-1"><span class="date">{{item.pushTime}}</span><span class="time">{{item.label}}</span></div>
						    <div class="intro">{{item.omitContent}}</div>
						    <a class="more-link" href="#" @click="toDetail(item.id)">更多 &rarr;</a>
					    </div>
				    </div>
			    </div>
			    
				<nav class="blog-nav nav nav-justified my-5">
					<input :hidden="true" v-model="currentPage">
					<a class="nav-link-prev nav-item nav-link rounded-left"  :style="{pointerEvents: preAuto}" href="#" @click="previousPage">上一页<i class="arrow-prev fas fa-long-arrow-alt-left"></i></a>
					<a class="nav-link-next nav-item nav-link rounded-right" :style="{pointerEvents: isAuto}" href="#" @click="nextPage">下一页<i class="arrow-next fas fa-long-arrow-alt-right"></i></a>
				</nav>
		    </div>
	    </section>
    </div>

</template>
<script setup>
import "../assets/css/theme-4.css"
import { watch, ref } from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {getContentList, searchContentList} from '@/api/blogApi';

const route = useRoute()
const router = useRouter()
watch(route, () => {

	if(route.query != undefined && route.query.ishref != undefined && route.query.ishref == 'true'){
		currentPage.value = 1
		searchTitle.value = '';
	    initData(route.query.menuKey);
		if(route.params != undefined && route.params.menuKey != undefined){
			router.push({path: '/' + route.params.menuKey, query: { ishref: false }});
		}
	}
})

const total = ref(0)
const isAuto = ref('none')
const preAuto = ref('none')
const dataList = ref([]);
const currentPage = ref(1)
const searchTitle = ref('')

const search = () => {

	router.push({path: '/'});
	preAuto.value = 'none'
	currentPage.value = 1
	let contentRequest = buildContentRequest()
	contentRequest.menuKey = 'undefined'
	searchContentList(contentRequest).then((res) => {
		dataList.value = []
        if(res.success == undefined || !res.success){
            message.error(res.errorMessage);
        }else{
			if(res != undefined && res.data != undefined){
				buildContentList(res.data.records)
			}
        }
    }).catch((err) => {
        console.log("加载文章列表异常!");
        console.log(err);
    });
}

const toDetail = (id) => {
	if(id != undefined){
		router.push({path: '/detail/' + id});
	}
}

const previousPage = () => {
	var page = currentPage.value - 1;
	currentPage.value = page;
	if(page == 1){
		preAuto.value = 'none'
	}
	if(page == 0){
		page = 1;
		preAuto.value = 'none'
	}
	initData();
}

const nextPage = () => {
	preAuto.value = 'auto'
	currentPage.value = currentPage.value + 1;
	initData();
}

const initData = (menuKey) => {

	if(currentPage.value == 1){
		preAuto.value = 'none'
	}
	
    searchContentList(buildContentRequest(menuKey)).then((res) => {

		dataList.value = []
        if(res.success == undefined || !res.success){
            message.error(res.errorMessage);
        }else{
			if(res != undefined && res.data != undefined){
				buildContentList(res.data.records)
			}
        }
    }).catch((err) => {
        console.log("加载文章列表异常!");
        console.log(err);
    });
}

const buildContentList = (records) => {

	total.value = records.length;
	if(total.value < 10){
		isAuto.value = 'none'
	}else{
		isAuto.value = 'auto'
	}
	if(records.length > 0){

		for(let index in records){
			var record = records[index];
			dataList.value.push({
				id: record.id,
				title: record.title,
				img: record.image,
				pushTime: record.createTime.replace(".000+00:00",""),
				label: record.label,
				omitContent: omitContentLength(record.omitContent, record.content)
			})
		}
	}else{
		dataList.value.push({
			title: '默认标题（该类型没有相关文章）',
			img: require('../assets/images/blog/blog-post-thumb-1.jpg'),
			pushTime: 'Published 1 months ago',
			label: '4 min read',
			omitContent: '.  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .' 
			+ '  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .'
			+ '  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .'
			+ '  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .  .'
		})
	}
}
const buildContentRequest = (menuKey) => {

	let contentRequest = {};
	if(searchTitle.value != ''){
		menuKey = 'undefined'
		contentRequest.title = searchTitle.value
	}else{
		contentRequest.title = 'undefined'
		if(menuKey != undefined){
		contentRequest.menuKey = menuKey;
		}else{
			if(menuKey == undefined && route.params == undefined){
				menuKey = 'undefined'
			}else{
				if(route.params.menuKey == undefined){
					menuKey = 'undefined'
				}else{
					menuKey = route.params.menuKey;
				}
			}
		}
	}

	contentRequest.menuKey = menuKey;
	contentRequest.currentPage = currentPage.value
	
	return contentRequest;
}
const omitContentLength = (omitContent, content) => {
	
	if(omitContent == undefined || omitContent == ''){
		omitContent = content.substr(0, 110);
	}
	
	var le = omitContent.length;
	if(le < 110){
		for(let i = 0; i < 54 - le; i ++){
			omitContent = omitContent + " . "
		}
	}
	return omitContent;
}
initData()
</script>
