import { JSEncrypt } from 'jsencrypt';

const publicKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjvugC1Tw9l/Dq09dwZKq1GclBhS+MHrflfh3qfeApeq+mvpFF8DRK5c0QP9yjBcpICyP7LSts9fgpJrqEIL8IPkk1YEsMtvFCkKzDscxeIlsLXVaOBf3gB9Em8PTSJikMJNZYDW1Z4+49r9bqSynoTivNpv5G9tlhzBLLzfRM5FJ8Dd1Et9HulbYc8F4zAXPOYpk4f9e+Jw5lrn6p+VSw/D6tTbzg79MQP7KCfPp4P32eFaf62yhxMEbAXUHD+iJiXx4IkWBmpv8gA9Z/YWf8i/3Ie0ao18J3ZgUXljYb8xYxdxKi0HURjUpEUHPu544IxeNpx2uytTxUJ7uvbkJ4wIDAQAB";

/**
 * rsa加密
 * @param {*} word
 * @returns
 */
export function setRsa(word) {
  const jsencrypt = new JSEncrypt();
  jsencrypt.setPublicKey(publicKey);
  return jsencrypt.encrypt(word);
}

/**
 * rsa解密
 * @param {*} word
 * @returns
 */
export function getRsa(word) {
  const decrypt = new JSEncrypt();
  decrypt.setPrivateKey(privateKey);
  return decrypt.decrypt(word);
}