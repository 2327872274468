import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import DetailBlog from './views/DetailBlog.vue'
import AddBlog from './views/AddBlog.vue'
import AddBlog_copy from './views/AddBlog_old.vue'
import ListBlog from './views/ListBlog.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/:menuKey',
        name: 'HomeMenu',
        component: Home
    },
    {
        path: '/list',
        name: 'ListBlog',
        component: ListBlog
    },
    {
        path: '/list/:menuKey',
        name: 'ListBlogMenu',
        component: ListBlog
    },
    {
        path: '/inAdd',
        name: 'AddBlog',
        component: AddBlog
    },
    {
        path: '/detail/:id',
        name: 'DetailBlog',
        component: DetailBlog
    },
    {
        path: '/inAdd_copy',
        name: 'AddBlog_copy',
        component: AddBlog_copy
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })
export default router;